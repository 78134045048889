<template>
  <div>
    <activeHeaderTime
      v-if="detail.promotion_type"
      :detail="detail"
      :times="detail.promotion_end_time"
    ></activeHeaderTime>

    <div class="goods_header">
      <div class="g-price flex-container" v-if="!detail.promotion_type">
        <p class="g-p-sale">
          <span>￥</span
          ><b ref="price">{{
            detail.promotion_type ? detail.promotion_price : detail.goods_price
          }}</b>
        </p>
        <p class="g-p-origin">
          价格￥<span>{{ detail.goods_marketprice }}</span>
        </p>
      </div>
      <div class="g-tag">
        <div class="tag">自营</div>
        <div class="salenum">
          月销{{ detail.goods_salenum + detail.goods_vr_salenum }}
        </div>
      </div>
      <div class="flex-container">
        <div class="g-title">
          <!-- " -->
          <div>
            <span
              class="tip"
              v-if="
                detail.goods_mgdiscount &&
                  detail.goods_mgdiscount.length != 0 &&
                  user &&
                  user.level
              "
              >会员折扣</span
            >
            <span
              v-if="detail.promotion_type == 'groupbuy'"
              class="tip"
              style="background: linear-gradient(to right, #ffd01e, #ff8917)"
              >限购商品</span
            >
            <!-- <template v-if="detail.goods_tip">
              <span
                class="tip"
                v-for="(item, index) in detail.goods_tip"
                :style="{
                  background: getMathColor(item),
                }"
                :key="index"
              >
                {{ getSubText(item) }}
              </span>
            </template> -->
          </div>
          <!-- <p class="title" v-title="detail.goods_name">
            {{ getFiltersName() }}
          </p> -->
          <p class="title">
            {{ getFiltersName() }}
          </p>
        </div>
        <!-- <div class="g-share">
          <img style="width: 30px; height: 30px" src="@dajiao/order/me_code.png" />
          <p>二维码</p>
        </div> -->
      </div>
      <div class="g-subtitle">
        <pre>{{ detail.goods_advword }}</pre>
      </div>

      <div class="g-h-icon flex-container">
        <span class="g-h-i-yj" @click="getYjCount">佣金</span>
        <span class="g-h-i-code" @click="codeImgBoo = true">二维码</span>
        <span class="g-h-i-share" @click="shareBoo = true">分享赚佣金</span>
      </div>

      <oCodeImg :boo.sync="codeImgBoo" :g_id="g_id"></oCodeImg>
      <oShareImg
        :boo.sync="shareBoo"
        :goodsName="detail.goods_name"
        :g_id="g_id"
      ></oShareImg>
    </div>
  </div>
</template>

<script>
import oCodeImg from "./share/codeImg.vue";
import oShareImg from "./share/share.vue";
import activeHeaderTime from "./activeHeader/time.vue";
import { Toast } from "vant";
import { post } from "@get/http";
import { mapState } from "vuex";
import { dataAdd } from "@set/util";
export default {
  data() {
    return {
      codeImgBoo: false,
      shareBoo: false,
    };
  },
  computed: mapState({
    user: (state) => state.user.info,
  }),
  props: {
    g_id: {
      default: "",
    },
    detail: {
      type: Object,
    },
  },
  created() {
    dataAdd({
      page: document.title,
      goods_id: this.g_id,
    });
  },
  watch: {
    "detail.goods_name"() {
      // document.title = this.detail.goods_name;
      dataAdd({
        page: document.title,
        goods_id: this.g_id,
      });
    },
  },

  components: {
    activeHeaderTime,
    oCodeImg,
    oShareImg,
  },
  methods: {
    getSubText(item) {
      /***
       * @method  getSubText 返回提示文本
       * @param item  当前sku参数
       * @return {string} 如返回提示文本
       *
       */
      let index = item.indexOf("[");
      let lastIndex = item.lastIndexOf("]");
      if (index != -1 && lastIndex != -1 && index != lastIndex) {
        return item.substring(lastIndex + 1, item.length);
      }
      return item;
    },
    getMathColor(item) {
      /***
       * @method  getMathColor 返回颜色
       * @param item  当前sku参数
       * @return {string} 如果有设置颜色则返回当前颜色 | 随机返回设定的颜色
       *
       */
      let index = item.indexOf("[");
      let lastIndex = item.lastIndexOf("]");
      if (index != -1 && lastIndex != -1 && index != lastIndex) {
        return item.substring(index + 1, lastIndex);
      }
      let color = [
          "#ff8917",
          "#ffd01e",
          "#e3b4b8",
          "rgb(190, 153, 255)",
          "rgb(114, 50, 221)",
          "#ee0a24",
          "#c45a65",
          "#806d9e",
          "#29b7cb",
        ],
        r = Math.round(Math.random() * 8);
      return color[r];
    },
    getFiltersName() {
      /***
       *  @method getFiltersName 因为标题和sku是连在一起的  所以要删掉SKU只保留主标题
       *  @return {string}  当前标题
       */
      let title = this.detail.goods_name;
      let sku = this.detail.goods_spec;

      for (const key in sku) {
        if (title.lastIndexOf(sku[key]) != -1) {
          return title.slice(0, title.lastIndexOf(sku[key]));
        }
      }
    },
    async getYjCount() {
      /**
       * 方法说明
       * @method getYjCount   佣金计算器
       * @param {Number} data 商品ID
       * @param {Number} price 产品价格
       * @return {Object} 返回可返多少佣金
       */

      let data = await post("/Membergoodsbrowse/getYongjin_v2", {
        data: {
          goods_id: this.detail.goods_id,
          goods_price: this.detail.promotion_price
            ? this.detail.promotion_price
            : this.$refs.price.innerHTML,
        },
      });
      Toast("可返" + data + "元。(仅供参考，以结算为准。)");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.goods_header {
  background: #fff;
  width: rem(710);
  margin: 0 auto;
  margin-top: rem(24);
  border-radius: rem(20);
  box-sizing: border-box;
  padding: rem(24);
  .g-price {
    // align-items: flex-end;
    .g-p-sale {
      color: rgb(236, 117, 32);
      font-size: 24px;
      span {
        font-size: 14px;
      }
    }
    .g-p-origin {
      margin-left: rem(12);
      color: #a3a3a3;
      margin-top: rem(20);
      font-size: 10px;
      span {
        text-decoration: line-through;
      }
    }
  }
  .g-h-icon {
    margin-top: rem(24);
    margin-bottom: rem(10);
    font-weight: 400;
    font-size: rem(26);
    line-height: rem(36);
    color: #666666;
    span {
      flex: 1;
      text-indent: rem(30);
      text-align: center;
    }
    .g-h-i-yj {
      background: url("https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022053010464674774.png")
        no-repeat rem(70) center;
      background-size: rem(26);
    }
    .g-h-i-code {
      background: url("https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022053010464682262.png")
        no-repeat rem(50) center;
      background-size: rem(28);
    }
    .g-h-i-share {
      background: url("https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022053010464625323.png")
        no-repeat rem(30) center;
      background-size: rem(24);
    }
  }
  .g-title {
    position: relative;

    .title {
      // text-indent: rem(120);
      font-size: rem(32);
      font-weight: 600;
      line-height: rem(45);
      margin-bottom: rem(10);
    }

    .tip {
      // position: absolute;
      display: inline-block;
      background: brown;
      margin: rem(4);
      padding: 0 6px;
      border-radius: rem(4);
      color: #fff;
      font-size: 10px;
      top: rem(8);
      border-radius: rem(6);
    }
  }
  .g-share {
    // height: 2.4rem;
    text-align: center;
    margin: 0 rem(40) 0 rem(30);
    p {
      font-size: rem(18);
    }
  }

  .g-subtitle {
    font-weight: 400;
    font-size: rem(26);
    line-height: rem(36);
    color: #999999;
  }
}

.g-tag {
  display: flex;
  padding: rem(24) 0;
  .tag {
    height: rem(32);
    display: flex;
    align-items: center;
    font-weight: 600;
    padding: 0 rem(11);
    font-size: rem(20);
    line-height: rem(28);
    color: #ffffff;
    background: #ff4848;
    text-align: center;
    border-radius: rem(16);
  }
  .salenum {
    font-weight: 400;
    font-size: rem(24);
    line-height: rem(34);
    color: #999999;
    flex: 1;
    text-align: right;
    margin-right: rem(12);
  }
}
</style>
